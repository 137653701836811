import "./promotion.styl";
import React, {useState} from "react";
import { ContentState, convertFromRaw, Editor, EditorState } from "draft-js";
import { Link } from "react-router-dom";
import { compositeDecorator } from "../utils/draft-editor";

export default function Promotion(props) {
  const {promotion: {body, slug, title, teaser }} = props;

  const teaserContentState = teaser && convertFromRaw(teaser);
  const bodyContentState = convertFromRaw(body);
  const snippetContentState = ContentState.createFromBlockArray([bodyContentState.getFirstBlock()]);
  const contentState = teaser ? teaserContentState : snippetContentState;
  const [editorState] = useState(EditorState.createWithContent(contentState, compositeDecorator));

  const titleContent = slug
  ? <Link to={`/articles/${slug}`}>{title}</Link>
  : title

  return (
    <div className="lbp-promotion">
      <h3>{titleContent}</h3>
      <Editor
        editorState={editorState}
        readOnly
      />
    </div>
  );
}
