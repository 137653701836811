import './staging-auth-gate.styl';
import React from 'react';
import { redirectForGoogleAuth } from "../utils/auth";
import AccentStripe from "./accent-stripe";
import Footer from "./footer";
import Header from "./header";

const StagingAuthGate = props => {
  const { authState, children } = props;

  return ['signedIn', 'verifyContact'].includes(authState)
    ? children
    : (
      <div className="lbp-app">
        <Header />
        <AccentStripe />
          <div className="lbp-staging-auth-gate">
            <button onClick={redirectForGoogleAuth}>
              <i className="fab fa-google"></i>
              Sign In
            </button>
          </div>
        <AccentStripe />
        <Footer />
      </div>
    );
}

export default StagingAuthGate;
