import './article-display.styl';
import React, { useState, useEffect } from 'react';
import { Redirect, useRouteMatch } from "react-router-dom";
import { convertFromRaw, Editor, EditorState } from "draft-js";
import { blockRendererFn, blockRenderMap, compositeDecorator } from "../utils/draft-editor";
import { fetchContentEffect } from "../hooks/fetch-content-effect";

export default function ArticleDisplay(props) {
  const {params: {slug}} = useRouteMatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty(compositeDecorator));
  const [article, setArticle] = useState();
  const [error, setError] = useState();

  const handleArticleResponse = response => {
    const contentState = convertFromRaw(response.body);
    setEditorState(EditorState.createWithContent(contentState, compositeDecorator));
    setArticle(response);
  }

  useEffect(
    fetchContentEffect({
      path: `https://${process.env.PUBLIC_API_URL}/articles/${slug}`,
      successHandler: handleArticleResponse,
      errorHandler: setError
    }),
    [slug]
  );

  useEffect(() => {
    if (window.gtag && article) {
      const meta = {page_title: article.title};
      window.gtag('config', 'page_view', meta);
      window.gtag('event', 'page_view', meta);
    }
  }, [window.gtag, article]);

  return error
    ? <Redirect to="/" />
    : article
      ? <>
          <div className="lbp-article-display">
            <h2>{article.title}</h2>
            <Editor
              blockRendererFn={blockRendererFn}
              editorState={editorState}
              readOnly
            />
          </div>
          <div className="lbp-article-display-footer" />
        </>
      : <div className="lbp-article-display"></div>
}
