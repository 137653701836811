import '../utils/auth';
import "./app.styl";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { Authenticator } from 'aws-amplify-react';
import AccentStripe from "./accent-stripe";
import ArticleDisplay from "./article-display";
import Features from "./features";
import Footer from "./footer";
import Header from "./header";
import Promotion from "./promotion";
import StagingAuthGate from "./staging-auth-gate";
import { fetchContentEffect } from "../hooks/fetch-content-effect";

const promotionSlug = 'welcome-to-little-book-paradewere-so-excited-youre-here';

const setArticlesWithFilter = setArticles => articles => setArticles(articles.filter(({slug}) => slug !== promotionSlug));

export function App() {
  const location = useLocation();
  const [articles, setArticles] = useState([])
  const [promotion, setPromotion] = useState();
 
  useEffect(
    fetchContentEffect({
      path: `https://${process.env.PUBLIC_API_URL}/articles`,
      successHandler: setArticlesWithFilter(setArticles)
    }),
    []
  );

  useEffect(
    fetchContentEffect({
      path: `https://${process.env.PUBLIC_API_URL}/articles/${promotionSlug}`,
      successHandler: setPromotion
    }),
    []
  )

  useEffect(() => {
    const meta = {page_path: location.pathname};
    if (window.gtag) {
      window.gtag('config', 'page_view', meta);
    }
  }, [window.gtag, location]);

  return (
    <div className="lbp-app">
      <Header />
      <Route exact path="/">
        {promotion && <Promotion promotion={promotion} />}
      </Route>
      <Switch>
        <Route path="/articles/:slug">
          <ArticleDisplay />
        </Route>
        <Route>
          <Features features={articles} />
        </Route>
      </Switch>
      <AccentStripe />
      <Footer />
    </div>
  );
}

const RoutedApp = props => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

const AuthenticatedApp = props => {
  return (
    <Authenticator hideDefault={true}>
      <StagingAuthGate>
        <RoutedApp />
      </StagingAuthGate>
    </Authenticator>
  );
};

export default (process.env.NODE_ENV === 'production' ? RoutedApp : AuthenticatedApp);
