import React from 'react';
import FeatureCard from './feature-card';
import './features.styl';

export default function Features(props) {
  const {features} = props;

  return (
    <div className="lbp-features">
      {features.map(feature => <FeatureCard key={feature.title} feature={feature} />)}
    </div>
  );
}
