import './feature-card.styl';
import React, { useState } from 'react';
import { ContentState, convertFromRaw, Editor, EditorState } from "draft-js";
import { useHistory } from "react-router-dom";
import { compositeDecorator } from "../utils/draft-editor";

const blockClick = evt => {
  evt.preventDefault();
  return false;
}

export default function FeatureCard(props) {
  const {feature: {body, slug, title, teaser, type}} = props;
  const history = useHistory();

  const teaserContentState = teaser && convertFromRaw(teaser);
  const bodyContentState = convertFromRaw(body);
  const snippetContentState = ContentState.createFromBlockArray([bodyContentState.getFirstBlock()]);
  let firstImage;
  const hasImage = Object.values(bodyContentState.entityMap).some(entityId => {
    firstImage = bodyContentState.getEntity(entityId);
    return firstImage.type === 'ARTICLE.IMAGE';''
  });
  const contentState = teaser ? teaserContentState : snippetContentState;
  const [editorState] = useState(EditorState.createWithContent(contentState, compositeDecorator));

  const className = ['lbp-feature-card'];

  if (type === 'interview') {
    className.push('lbp-feature-card--interview');
  }

  return (
    <div className={className.join(' ')} onClick={() => history.push(`/articles/${slug}`)}>
      <h3><a href={`/articles/${slug}`} onClick={blockClick}>{title}</a></h3>
      {hasImage && <img src={firstImage.data.src} />}
      <Editor
        editorState={editorState}
        readOnly
      />
    </div>
  );
}
