import React from 'react';
import './header.styl';
import Banner from '../assets/lbp-banner.svg'

export default function Header() {
  return (
    <>
      <div className="lbp-header">
        <Banner />
        <h1><a href="/">Little Book Parade</a></h1>
      </div>
      <div className="lbp-subheader"><h2>Find your child’s next favorite book!</h2></div>
    </>
  );
}